import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from 'components';
import React, { useCallback } from 'react';
import { useForm, useUIStore } from 'utils/hooks';
import { ZCCApi } from '../../../apis';
import {
  ZCCPatientOutreachContactAttempt,
  ZCCPatientOutreachContactAttemptOptions,
  ZCCPatientOutreachContactOutcomeOptions,
} from '../../../apis/zcc';
import Modal, { ModalPropType } from '../templates/Modal';
import styles from './style.module.scss';

export type RecordPatientOutreachProps = ModalPropType & {
  referralId: string;
  previousContactAttempt?: ZCCPatientOutreachContactAttempt[];
};

export default function RecordPatientOutreach({
  referralId,
  previousContactAttempt = [],
  onClose,
  scrollTop,
  modalProps,
}: RecordPatientOutreachProps) {
  const { openAlert } = useUIStore();
  const { bind, form } = useForm({});

  const onSubmitError = async () => {
    form.setFormError('Please fix the errors below');
    scrollTop();
  };

  const onSubmit = useCallback(async () => {
    const { attempt, outcome } = form.values;
    try {
      await ZCCApi.recordPatientOutreachContact({
        referralId,
        contactAttempt: attempt,
        contactOutcome: outcome,
      });

      openAlert({
        title: `Patient Outreach Recorded.`,
        duration: 3000,
      });
      onClose?.();
    } catch (error: any) {
      const errors = error?.response?.data?.errors || {};
      const errorDetails = error?.response?.data?.detail;
      const formattedError = Object.values(errors).join(' ');
      form.setFormError(formattedError || errorDetails || `An error occurred`);
      scrollTop();
    }
  }, [form.values, referralId]);

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  return (
    <Modal
      {...modalProps}
      alert={form.formError}
      title='Record Patient Outreach'
      className={styles.base}
      backdropClose={false}
      onSubmit={form.handleSubmit(onSubmit, onSubmitError)}
      actions={[
        { label: 'Cancel', action: 'close' },
        { label: 'Save Contact', action: 'submit', disabled: !form.values?.attempt || !form.values?.outcome },
      ]}>
      <Grid className={styles.fields} container spacing={3}>
        <Grid item xs={6}>
          <TextField {...bind('attempt', { required: false })} select label='Contact Attempt' {...fieldProps}>
            {ZCCPatientOutreachContactAttemptOptions.map(({ label, value }) => (
              <MenuItem
                key={value}
                value={value}
                disabled={previousContactAttempt?.includes(value as ZCCPatientOutreachContactAttempt)}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField {...bind('outcome', { required: false })} select label='Contact Outcome' {...fieldProps}>
            {ZCCPatientOutreachContactOutcomeOptions.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Modal>
  );
}
