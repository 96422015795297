import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import moment from 'moment/moment';
import { FC, useCallback, useMemo } from 'react';
import { useUIStore } from 'utils/hooks';
import {
  ZCCPatientOutreachContactAttempt,
  ZCCPatientOutreachContactAttemptOptions,
  ZCCPatientOutreachContactOutcome,
  ZCCPatientOutreachContactOutcomeOptions,
} from '../../../apis/zcc';
import { getCalendarTime } from '../../../utils/helper';
import FieldTitle from '../../FieldTitle';
import styles from '../../modals/RecordPatientOutreach/style.module.scss';

export type PatientOutreachProps = {
  referralId: string;
  patientContacts?: {
    contact_attempt: ZCCPatientOutreachContactAttempt;
    contact_outcome: ZCCPatientOutreachContactOutcome;
    timestamp: string;
  }[];
  onChange?: () => void;
};

const PatientOutreach: FC<PatientOutreachProps> = ({ referralId, patientContacts = [], onChange }) => {
  const { openModal } = useUIStore();

  const previousContactAttempt = useMemo(() => {
    return (patientContacts || []).map((pc: any) => pc.contact_attempt);
  }, [patientContacts]);

  const handleOpenPatientOutreachModal = useCallback(() => {
    openModal({
      id: 'record-patient-outreach',
      props: {
        referralId,
        previousContactAttempt,
      },
      callback: () => {
        onChange?.();
      },
    });
  }, [referralId, previousContactAttempt]);

  return (
    <div>
      <Grid className={styles.fields} container spacing={3}>
        <Grid item xs={12}>
          <FieldTitle icon='phone' margin>
            Patient Outreach
          </FieldTitle>
        </Grid>
        {patientContacts.map((contact) => {
          return (
            <Grid key={contact.timestamp} item xs={12}>
              <div className={styles.contact}>
                <span className={styles.timestamp}>
                  {getCalendarTime(contact.timestamp, true)}, {moment.utc(contact.timestamp).local().format('h:mma z')}
                </span>
                {' - '}
                <span className={styles.attempt}>
                  {ZCCPatientOutreachContactAttemptOptions.find((o: any) => o.value === contact.contact_attempt)?.label}
                </span>
                {', '}
                <span className={styles.outcome}>
                  {ZCCPatientOutreachContactOutcomeOptions.find((o: any) => o.value === contact.contact_outcome)?.label}
                </span>
              </div>
            </Grid>
          );
        })}

        <Grid item xs={12}>
          <Button variant='outlined' color='primary' size='large' onClick={() => handleOpenPatientOutreachModal()}>
            Record Patient Outreach
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default PatientOutreach;
